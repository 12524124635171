import {
  ActionDefinition,
  ConnectionBuilder,
} from '../utils/manifest-definitions';
import {Translations} from '../utils/translations';
import {getCurrentVersion} from '../utils/ci-actions';
import {EditorSdkAdapter} from '@wix/bookings-adapter-editor-sdk/dist/src/editor-sdk-adapter';

export class ConnectedComponentModel {
  readonly connection: any;
  readonly role: string;
  readonly labelKey: string;

  constructor(role, connection, labelKey?: string) {
    this.connection = connection;
    this.role = role;
    this.labelKey = labelKey;
  }

  get shouldTranslateLabel() {
    return !!this.labelKey;
  }
}

export interface BaseComponentModelProps {
  nickname: string;
  label: string;
  mainAction1: ActionDefinition;
  mainAction2: ActionDefinition;
  helpId: string;
  connectedComponents?: ConnectedComponentModel[];
  isButtonWidget?: boolean;
  linkedStyles?: string[][];
}

export class BaseComponentModel {
  readonly nickname: string;
  readonly label: string;
  readonly mainAction1: ActionDefinition;
  readonly mainAction2: ActionDefinition;
  readonly helpId: string;
  readonly isButtonWidget: boolean;
  readonly linkedStyles: string[][];
  connectedComponents?: ConnectedComponentModel[];

  constructor({nickname, label, mainAction1, mainAction2, helpId, connectedComponents, isButtonWidget, linkedStyles}: BaseComponentModelProps) {
    this.nickname = nickname;
    this.label = label;
    this.mainAction1 = mainAction1;
    this.mainAction2 = mainAction2;
    this.helpId = helpId;
    this.isButtonWidget = isButtonWidget;
    this.connectedComponents = connectedComponents;
    this.linkedStyles = linkedStyles || [];
  }
}

export class BaseEditorComponent {
  readonly baseComponentModel: any;
  readonly editorSdkAdapter: EditorSdkAdapter;

  constructor(editorSdkAdapter, componentModel: BaseComponentModel) {
    this.editorSdkAdapter = editorSdkAdapter;
    this.baseComponentModel = componentModel;
  }

  private getTranslatableComponents() {
    if(this.baseComponentModel.connectedComponents?.length > 0) {
      return this.baseComponentModel.connectedComponents.filter(component => component.shouldTranslateLabel);
    }
    return [];
  }

  async translateLabels(componentRef) {
    const siteLanguageCode = await this.editorSdkAdapter.getRegionalLanguage();
    const siteTranslation = new Translations();
    await siteTranslation.init(siteLanguageCode, await getCurrentVersion(this.editorSdkAdapter));
    const translatableComponents = this.getTranslatableComponents();
    const updateTranslationPromises = [];
    translatableComponents.forEach((component) => {
      updateTranslationPromises.push((async () => {
        const translatedComponentRef = await this.editorSdkAdapter.getConnectedComponentRefByRole(componentRef, component.role);
        await this.editorSdkAdapter.setComponentText(translatedComponentRef, siteTranslation.t(component.labelKey));
      })());
    });
    return Promise.all(updateTranslationPromises);
  }

  getLinkedStyles(styleRole: string) {
    return this.baseComponentModel.linkedStyles.find(linkedStylesGroup => linkedStylesGroup.find((linkedStyleRole) => linkedStyleRole === styleRole)).filter((linkedStyleRole) => linkedStyleRole !== styleRole);
  }

  async handleStylesChange(controllerRef, styledComponentRef) {
    const styledComponentRole = await this.editorSdkAdapter.getComponentRole(styledComponentRef);
    const sourceStyle = await this.editorSdkAdapter.getComponentStyle(styledComponentRef)
    const linkedStyles = this.getLinkedStyles(styledComponentRole)
    const updateStylesPromises = [];
    linkedStyles.forEach((linkedStyleRole) => {
      updateStylesPromises.push((async () => {
        const targetComponentRef = await this.editorSdkAdapter.getConnectedComponentRefByRole(controllerRef, linkedStyleRole);
        await this.editorSdkAdapter.setComponentStyle(targetComponentRef, sourceStyle);
      })());
    });
    return Promise.all(updateStylesPromises);
  }

  get connection() {
    let baseConnectionBuilder = new ConnectionBuilder()
      .withNickname(this.baseComponentModel.nickname)
      .withLabel(this.baseComponentModel.label)
      .withMainAction1(this.baseComponentModel.mainAction1)
      .withMainAction2(this.baseComponentModel.mainAction2)
      .withHelpId(this.baseComponentModel.helpId)

    let connections = {};
    if(this.baseComponentModel.connectedComponents?.length > 0) {
      this.baseComponentModel.connectedComponents.forEach(component => {
        connections[component.role] = component.connection;
      });
      baseConnectionBuilder.withConnections(connections);
    }
    if (this.baseComponentModel.isButtonWidget) {
      baseConnectionBuilder
        .withoutFirstTimeModal()
        .withDisabledLinkAbility()
        .withDisabledRotatability()
    }
    return baseConnectionBuilder.build();
  }
}
